import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { CampaignsService } from '@/services/CampaignsService';
import { useCampaignsStore } from '@/stores/useCampaignsStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { Box, Divider, Flex, Grid, Select, Text, Tooltip } from '@mantine/core';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsByBrowser } from './Components/ShortLinks/AnalyticsByBrowser';
import { AnalyticsByDevice } from './Components/ShortLinks/AnalyticsByDevice';
import { AnalyticsByLocation } from './Components/ShortLinks/AnalyticsByLocation';
import { AnalyticsByReferral } from './Components/ShortLinks/AnalyticsByReferral';
import { AnalyticsHistogram } from './Components/ShortLinks/AnalyticsHistogram';
import { AnalyticsOverview } from './Components/ShortLinks/AnalyticsOverview';
import { endOfDay, startOfDay } from 'date-fns';
import CustomDateMenu from '@/components/Menus/DateMenu/CustomDateMenu';
import InfiniteScrollSelect from '@/components/InfiniteScrollSelect/InfiniteScrollSelect';

export const AnalyticsShortLinksPage = () => {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  const location = useLocation();
  const [campaigns] = useCampaignsStore((state) => [state.campaigns]);
  const [selectedCampaign, setSelectedCampaign] = useState<string | null>(null);
  const [selectedLink, setSelectedLink] = useState<string | null>(null);
  const [linksSuggestions, setLinksSuggestions] = useState<any[]>([]);
  const [isLoadingLinks, setIsLoadingLinks] = useState(false);
  const [linkPage, setLinkPage] = useState(1);
  const [hasMoreLinks, setHasMoreLinks] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const fetchLinks = async (page: number, append: boolean = false, search: string = '') => {
    if (!selectedCampaign || !defaultWorkspace?._id || isLoadingLinks) return;

    setIsLoadingLinks(true);
    try {
      const res = await new CampaignsService().getLinksbyCampaignId(
        defaultWorkspace._id,
        selectedCampaign,
        page,
        20,
        search
      );
      if (res.data.status) {
        const newLinks = res.data.data;
        setLinksSuggestions(prev => append ? [...prev, ...newLinks] : newLinks);
        setHasMoreLinks(res.data.meta.hasMore);
        setLinkPage(page);
      }
    } catch (error) {
      console.error('Error fetching links:', error);
    } finally {
      setIsLoadingLinks(false);
    }
  };

  const handleLinkSearch = (query: string) => {
    setSearchQuery(query);
  };

  const loadMoreLinks = () => {
    if (hasMoreLinks && !isLoadingLinks) {
      fetchLinks(linkPage + 1, true, searchQuery);
    }
  };

  /**
   * Handle date change
   * @param value
   */
  const handleDateChange = (startDate: string | null, endDate: string | null, granularity: string) => {
    if (!startDate || !endDate) {
      // Handle "All time" case
      queryParams.set(
        'from_date',
        startOfDay(new Date(defaultWorkspace?.created_at || '')).toISOString()
      );
      queryParams.set('to_date', endOfDay(new Date()).toISOString());
    } else {
      queryParams.set('from_date', startDate);
      queryParams.set('to_date', endDate);
    }
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const handleCampaignChange = (value: string | null) => {
    setSelectedCampaign(value);
    setSelectedLink(null);
    queryParams.set('campaign_id', value || '');
    queryParams.delete('link_id');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  const handleLinkChange = (value: string | null) => {
    setSelectedLink(value);
    queryParams.set('link_id', value || '');
    const newSearch = `?${queryParams.toString()}`;
    navigate(newSearch);
  };

  // Handle URL params and initial load
  useEffect(() => {
    const campaignId = queryParams.get('campaign_id');
    const linkId = queryParams.get('link_id');

    if (campaignId !== selectedCampaign) {
      setSelectedCampaign(campaignId);
      setSelectedLink(linkId);
    }
  }, [queryParams, defaultWorkspace?._id]);

  useEffect(() => {
    if (selectedCampaign) {
      // Reset states
      setLinkPage(1);
      setHasMoreLinks(true);
      setSearchQuery('');
      setLinksSuggestions([]); // Clear previous links

      // Fetch initial links for the selected campaign
      fetchLinks(1, false);
    } else {
      // Clear links when no campaign is selected
      setLinksSuggestions([]);
      setHasMoreLinks(false);
    }
  }, [selectedCampaign]); // Only depend on selectedCampaign

  // Handle search with debounce
  useEffect(() => {
    if (!selectedCampaign) return;

    const timeoutId = setTimeout(() => {
      setLinkPage(1);
      fetchLinks(1, false, searchQuery);
    }, 300);

    return () => clearTimeout(timeoutId);
  }, [searchQuery]);

  return (
    <>
      <Divider />
      <Flex
        mt={16}
        // p={16}
        // px={24}
        align={'center'}
        style={
          {
            //   background: 'var(--mantine-color-white)',
          }
        }
      >
        <Flex style={{ flexGrow: 1 }}>
          <InfiniteScrollSelect
            options={campaigns.map((campaign) => ({
              value: campaign._id,
              label: campaign.name,
            }))}
            value={selectedCampaign}
            onChange={handleCampaignChange}
            placeholder="Filter by a campaign"
            canClear={true}
          />

          <Box ml={12}>
            <Tooltip disabled={selectedCampaign !== null} label="Please select a campaign first">
              <div>
                <InfiniteScrollSelect
                  options={linksSuggestions}
                  value={selectedLink}
                  onChange={handleLinkChange}
                  onSearch={handleLinkSearch}
                  onScrollEnd={loadMoreLinks}
                  disabled={selectedCampaign === null}
                  placeholder="Filter by a link"
                  isLoading={isLoadingLinks}
                  canClear={true}
                />
              </div>
            </Tooltip>
          </Box>
        </Flex>
        {/* <DateMenu onChange={handleDateChange} labelValue="Last 30 days" /> */}
        <CustomDateMenu
          showButtons={{
            all_time: true,
            last_24_hours: true,
            last_7_days: true,
            last_14_days: true,
            last_30_days: true,
            last_90_days: false,
            last_6_months: true,
            last_1_year: true,
            this_month: true,
            last_month: true,
            "7D": false,
            "14D": false,
            "30D": false,
            "3M": false,
            "6M": false,
            "12M": false,
            "no-range": false,
            custom: true
          }}
          onDateChange={handleDateChange}
          value={[
            queryParams.get('from_date') || null,
            queryParams.get('to_date') || null
          ]}
          withGranularity={false}
        />
      </Flex>
      <Box my={16}>
        <Grid>
          <Grid.Col span={12}>
            <AnalyticsOverview />
          </Grid.Col>
          <Grid.Col span={12}>
            <Box
              h={306}
              bg={'white'}
              style={{
                boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.05)',
                border: '1px solid var(--mantine-color-gray-2)',
                borderRadius: 4,
                padding: 16,
              }}
            >
              <Flex direction={'column'}>
                <Text fw={600} c="dimmed" mb={12}>
                  Clicks vs Unique Clicks vs QR Scans
                </Text>
                <AnalyticsHistogram />
              </Flex>
            </Box>
          </Grid.Col>
        </Grid>
      </Box>
      <Grid>
        <Grid.Col span={6}>
          <AnalyticsByReferral />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByLocation />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByBrowser />
        </Grid.Col>
        <Grid.Col span={6}>
          <AnalyticsByDevice />
        </Grid.Col>
      </Grid>
    </>
  );
};

import {
  Box,
  Flex,
  Group,
  ScrollArea,
  Space,
  TextInput,
  Title,
  useMantineTheme,
} from '@mantine/core';
import { DateMenu } from '@/components/Menus/DateMenu/DateMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
import { useLinksStore } from '@/stores/useLinksStore';
import { useCallback, useEffect, useRef } from 'react';
import { LinksTableWithInfiniteScroll } from '../Links/LinksTableWithInfiniteScroll';

export function DashboardLinks() {
  const theme = useMantineTheme();
  const [search, setSearch, setDate, page, setPage, lastPage] = useLinksStore((state) => [
    state.search,
    state.setSearch,
    state.setDate,
    state.page,
    state.setPage,
    state.lastPage
  ]);

  // Ref to store scroll position
  const scrollAreaRef = useRef<HTMLDivElement>(null);
  const isLoadingRef = useRef(false);

  // Debounced scroll handler to prevent multiple calls
  const handleScroll = useCallback(({ y }: { y: number }) => {
    const element = document.querySelector('.mantine-ScrollArea-viewport');
    if (!element || isLoadingRef.current) return;

    const totalHeight = element.scrollHeight - element.clientHeight;
    if (totalHeight - y < 100 && page < lastPage) {
      isLoadingRef.current = true;
      setPage(page + 1);
      
      setTimeout(() => {
        isLoadingRef.current = false;
      }, 500);
    }
  }, [page, lastPage, setPage]);

  return (
    <>
      <Box
        h={'100%'}
        style={{
          //   border: '2px dashed var(--mantine-color-gray-4)',
          //   borderSpacing: '8px',
          backgroundColor: 'var(--mantine-color-white)',
          borderRadius: '0.25rem',
        }}
      >
        <Group p={'sm'} grow>
          <Title order={5}>Recent Links</Title>
          <Flex direction={'row-reverse'}>
            {/* <DateMenu onChange={setDate} /> */}
            <Space w={8} />
            <TextInput
              w={160}
              size="xs"
              ml={8}
              value={search}
              placeholder="Search Links"
              onChange={(event) => setSearch(event.currentTarget.value)}
              leftSection={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    color: theme.colors.gray[5],
                  }}
                />
              }
            />
          </Flex>
        </Group>
        <ScrollArea
          h={600}
          onScrollPositionChange={handleScroll}
          viewportRef={scrollAreaRef}
        >
          <LinksTableWithInfiniteScroll theadBg={'white'} excludeColumns currentPage={page}/>
        </ScrollArea>
      </Box>
    </>
  );
}

import { AuthCarousel } from '@/components/Auth/AuthCarousel';
import {
  Anchor,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Image,
  Paper,
  PasswordInput,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { useForm } from '@mantine/form';
import { useNavigate } from 'react-router-dom';
import classes from './auth.module.css';
import { API_URL, APP_ENVIRONMENT } from '@/constants/Constants';
import axios from 'axios';
import { notifications } from '@mantine/notifications';
import { useUserStore } from '@/stores/useUserStore';
import { setAuthTokenLocalStorage } from '@/utils/Storage';
import { useState } from 'react';
import { getAppLogo, isWhitelabelDomain } from '@/utils/CommonUtility';
import { useDocumentTitle } from '@mantine/hooks';

export function LoginPage() {
  const [loading, setLoading] = useState(false);
  const [setUser] = useUserStore((state) => [state.setUser]);
  const navigate = useNavigate();
  // Check the current domain
  if (window.location.hostname === 'app.replug.io' || window.location.hostname === 'dev.replug.io') {
    useDocumentTitle('Login | Replug');
  } else {
    useDocumentTitle('Login');
  }
  const form = useForm({
    initialValues: {
      email: '',
      password: '',
      remember_me: false,
      // get source from query string
      source: new URLSearchParams(window.location.search).get('source') || '',
      // get redirect from query string
      redirect: new URLSearchParams(window.location.search).get('redirect') || '',
    },

    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
      password: (value) => (value.trim().length > 0 ? null : 'Password is required'),
    },
  });

  const login = async (formValues: any) => {
    setLoading(true);
    await axios
      .post(`${API_URL}/auth/login`, formValues)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {

          if (res.data?.frill_redirect) {
            window.location.href = res.data.frill_redirect;
            return;
          }

          if (!res.data.is_workspace_member) {
            notifications.show({
              color: 'red',
              message: 'You are not added to any Workspace, Please contact admin.',
            });
            return;
          }
          setAuthTokenLocalStorage(res.data.authorisation.token);
          setUser(res.data.user);
          if (!res.data.user.email_verified_at) {
            navigate('/verification');
          } else {
            // Get the full query string
            const queryString = window.location.search;
            // Extract the entire redirect URL
            const redirectMatch = queryString.match(/[?&]redirect=([^#]+)/);
            const redirect = redirectMatch ? decodeURIComponent(redirectMatch[1]) : null;

            console.debug("redirect: ", redirect);
            redirect ? navigate(redirect) : navigate('/dashboard');
          }
        } else {
          notifications.show({
            color: 'red',
            message: res.data.message,
          });
        }
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 422) {
          notifications.show({
            color: 'red',
            message: err.response.data.message,
          });
          return;
        }
        notifications.show({
          color: 'red',
          message: 'Something went wrong',
        });
      });
    setLoading(false);
  };

  console.debug(import.meta.env);

  const handleGoogleLogin = async () => {
    try {
      const response = await axios.get(`${API_URL}/auth/google`);
      if (response.data.status === 'success' && response.data.url) {
        window.location.href = response.data.url;
      }
    } catch (error) {
      notifications.show({
        color: 'red',
        message: 'Failed to initiate Google login'
      });
    }
  };

  return (
    <Paper
      style={{
        backgroundColor: '#f2f6f7',
        minHeight: '100vh', // Ensure full height
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden', // Prevent scrolling
      }}
    >
      <Grid>
        <Grid.Col
          span={3.5}
          style={{
            height: '100%',
          }}
        >
          <Flex mt={16} mx={16} direction="column" className={classes.authContainer}>
            <Box className={classes.authLogoContainer}>
              <Image w={122} h={36} src={getAppLogo()} />
            </Box>
            <Stack h="100%" justify="space-between">
              <Box p={16} px={32}>
                <Title order={4} ta="center" pt={20} pb={40}>
                  Log in to your account
                </Title>

                {/* Add Google Sign In Button */}
                {/* {APP_ENVIRONMENT !== 'production' && (<> */}

                  <Button
                    variant="outline"
                    fullWidth
                    leftSection={
                      <Image
                        src="https://www.google.com/favicon.ico"
                        width={20}
                        height={20}
                        alt="Google"
                      />
                    }
                    onClick={handleGoogleLogin}
                    mb={24}
                  >
                    Sign in with Google
                  </Button>

                  <Divider
                    label="Or continue with email"
                    labelPosition="center"
                    mb={24}
                  />
                {/* </>)} */}

                <form onSubmit={form.onSubmit(login)}>
                  <TextInput
                    mb={16}
                    label="Email"
                    placeholder="e.g john.carl@email.com"
                    {...form.getInputProps('email')}
                  />
                  <PasswordInput
                    mb={16}
                    label="Password"
                    placeholder="e.g ********"
                    {...form.getInputProps('password')}
                  />
                  <Flex mb={24} align="center">
                    <Checkbox
                      size="xs"
                      label="Remember me"
                      {...form.getInputProps('remember_me')}
                    />
                    <Anchor
                      fw={600}
                      size="xs"
                      style={{
                        marginLeft: 'auto',
                      }}
                      onClick={() => navigate('/forgot-password')}
                    >
                      Forgot password?
                    </Anchor>
                  </Flex>
                  <Box
                    mt={40}
                    size="md"
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Button loading={loading} disabled={loading} type="submit" w={140}>
                      Sign in
                    </Button>
                  </Box>
                  {!isWhitelabelDomain() && (
                    <Flex p={16} pt={30} direction="column" align="center">
                      <Text pb={8} size="sm">
                        Don’t have an account?{' '}
                        <Anchor onClick={() => navigate('/register')} fw="bold">
                          Sign up
                        </Anchor>
                        .
                      </Text>
                    </Flex>
                  )}
                </form>
              </Box>
              <Flex p={16} direction="column" align="center">
                <Text pb={16} size="sm" ta="center" c="dimmed">
                  Our{' '}
                  <Anchor onClick={() => window.open('https://replug.io/terms', '_blank')}>
                    terms of service
                  </Anchor>{' '}
                  and{' '}
                  <Anchor onClick={() => window.open('https://replug.io/privacy', '_blank')}>
                    privacy policy
                  </Anchor>
                  .
                </Text>
              </Flex>
            </Stack>
          </Flex>
        </Grid.Col>
        <Grid.Col span={8.5}>
          <AuthCarousel />
        </Grid.Col>
      </Grid>
    </Paper>
  );
}

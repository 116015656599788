import { LoaderDataTable } from '@/components/Menus/LoaderDataTable/LoaderDataTable';
import { WorkspaceService } from '@/services/WorkspaceService';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { IWorkspace } from '@/types/workspace';
import { getRelativeTime } from '@/utils/DateUtility';
import {
  faLineChart,
  faLock,
  faMagnifyingGlass,
  faPencil,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Alert,
  Avatar,
  Box,
  Button,
  Center,
  Flex,
  Grid,
  List,
  Modal,
  Skeleton,
  Switch,
  Table,
  Text,
  TextInput,
  Title,
  Tooltip,
  useMantineTheme,
  Pagination,
  Group
} from '@mantine/core';
import { useDebouncedValue } from '@mantine/hooks';
import { modals } from '@mantine/modals';
import { notifications } from '@mantine/notifications';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { WorkspaceCreateModal } from '../WorkspaceCreateModal/WorkspaceCreateModal';
import { numberToCommas } from '@/utils/StringUtility';
import { format, subDays } from 'date-fns';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission, isUserCollaborator, isWorkspaceLocked } from '@/utils/CommonUtility';
import { PlansModal } from '../PlansModal/PlansModal';
import { usePlanStore } from '@/stores/usePlanStore';

export function ManageWorkspacesModal() {
  const [
    manageWorkspacesToggle,
    setManageWorkspacesToggle,
    setCreateToggle,
    storeWorkspaces,
    setStoreWorkspaces,
  ] = useWorkspaceStore((state) => [
    state.manageWorkspaceToggle,
    state.setManageWorkspaceToggle,
    state.setCreateToggle,
    state.workspaces,
    state.setWorkspaces,
  ]);

  const [defaultWorkspace, setDefaultWorkspace] = useWorkspaceStore((state) => [
    state.defaultWorkspace,
    state.setDefaultWorkspace,
  ]);

  const [plan, upgradeModalState, setUpgradeModalState] = usePlanStore((state) => [state.plan, state.upgradeModalState, state.setUpgradeModalState]);
  const [search, setSearch] = useState('');
  const [debounced] = useDebouncedValue(search, 200);
  const [user] = useUserStore((state) => [state.user]);
  const [workspaces, setWorkspaces] = useState<IWorkspace[]>([]);
  const [loading, setLoading] = useState(false);
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const [workspaceStats, setWorkspaceStats] = useState([] as any);
  const [workspaceStatsLoading, setWorkspaceStatsLoading] = useState(true);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [perPage] = useState(10);
  const handleWorkspaceChange = (workspace: any) => {
    if (isWorkspaceLocked(workspace)) return;
    setDefaultWorkspace(workspace);
    setManageWorkspacesToggle(false)
    navigate(`/dashboard/${workspace._id}`);
  };

  const handleDefaultWorkspace = async (workspace: IWorkspace) => {
    console.log(workspace);
    new WorkspaceService()
      .setAsDefault(defaultWorkspace?._id || '', workspace._id)
      .then((res) => {
        if (res.data.status) {
          const modifiedWorkspaces = workspaces.map((item: IWorkspace) => {
            if (item._id === workspace._id) {
              item.is_default = true;
            } else {
              item.is_default = false;
            }
            return item;
          });
          setWorkspaces(modifiedWorkspaces);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchWorkspaces = async () => {
    setLoading(true);
    await new WorkspaceService()
      .getAllPaginated(page, perPage, debounced)
      .then((res) => {
        setWorkspaces(res.workspaces);
        setTotalPages(res.pagination.total_pages);
      })
      .catch((err) => {
        console.log(err);
      });
    setLoading(false);
  };

  const fetchWorkspaceStats = async () => {
    setWorkspaceStatsLoading(true);
    await new WorkspaceService()
      .stats(defaultWorkspace?._id || '')
      .then((res) => {
        if (res.data.data) {
          setWorkspaceStats(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setWorkspaceStatsLoading(false);
  };

  const handleDeleteWorkspace = async (workspace: IWorkspace) => {
    // TODO: If the selected workspace has been removed, we need to change the workspace to a different one.

    const response = await new WorkspaceService()
      .delete(defaultWorkspace?._id || '', workspace._id)
      .then((res) => {
        if (res.data.status) {
          const modifiedWorkspaces = workspaces.filter(
            (item: IWorkspace) => item._id !== workspace._id
          );
          setWorkspaces(modifiedWorkspaces);

          // unset active workspace if it is deleted

          if (workspace._id === defaultWorkspace?._id) {
            setDefaultWorkspace(modifiedWorkspaces[0]);
          }

          notifications.show({
            color: 'green',
            message: 'Workspace deleted successfully.',
          });
          return true;
        } else {
          notifications.show({
            color: 'red',
            message: res?.data?.message,
          });
          return false;
        }
      })
      .catch((err) => {
        console.log(err);
        return false;
      });

    if (response) {
      const modifiedWorkspaces = [...storeWorkspaces];
      const index = modifiedWorkspaces.findIndex((item: IWorkspace) => item._id === workspace._id);
      if (index > -1) {
        modifiedWorkspaces.splice(index, 1);
        setStoreWorkspaces(modifiedWorkspaces);
      }
    }
  };

  const confirmDeleteModal = (workspace: IWorkspace) => {
    modals.openConfirmModal({
      title: <Title order={5}>Delete Workspace</Title>,
      children: (
        <>
          <Text size="sm">
            Do you want to delete <strong>"{workspace.name}"</strong>? This process can not be
            undone.
          </Text>
          <Alert color="yellow" my={16} p={16}>
            <List>
              <List.Item>Campaigns will be removed.</List.Item>
              <List.Item>Links will be removed.</List.Item>
              <List.Item>RSS automation will be removed.</List.Item>
              <List.Item>RSS feed links will be removed.</List.Item>
              <List.Item>Bio Links will be removed.</List.Item>
              <List.Item>UTM tags will be removed.</List.Item>
              <List.Item>Custom widgets will be removed.</List.Item>
              <List.Item>Reports will be removed.</List.Item>
              <List.Item>Integrations will be removed.</List.Item>
              <List.Item>Custom domains will be removed.</List.Item>
            </List>
          </Alert>
        </>
      ),
      labels: { confirm: 'Confirm', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onCancel: () => console.log('Cancel'),
      onConfirm: () => handleDeleteWorkspace(workspace),
    });
  };

  const findStatsByWorkspaceId = (workspace_id: string) => {
    const item = workspaceStats?.find((item: any) => item.workspace_id === workspace_id);
    if (item) {
      return item;
    }
    return {
      clicks: 0,
      unique_clicks: 0,
      qr_scans: 0,
    };
  };

  useEffect(() => {
    if (manageWorkspacesToggle) {
      fetchWorkspaces();
      fetchWorkspaceStats();
    }
  }, [manageWorkspacesToggle, storeWorkspaces]);

  // Add useEffect to refetch when page changes
  useEffect(() => {
    if (manageWorkspacesToggle) {
      fetchWorkspaces();
    }
  }, [page, debounced, manageWorkspacesToggle]);

  useEffect(() => {
    setPage(1);
  }, [debounced]);

  return (
    <>
      {!window.location.pathname?.includes('billing') && (
        <PlansModal
          isOpen={upgradeModalState}
          primarySubscription={plan}
          showTrialPlans={false}
          currentPlanId={plan?.paddle_id}
          onClose={() => {
            setUpgradeModalState(false);
          }}
          fetchSubscriptions={() => { }}
        />
      )}
      <WorkspaceCreateModal />
      <Modal
        styles={{
          header: {
            backgroundColor: theme.colors.gray[1],
          },
          content: {
            backgroundColor: theme.colors.gray[1],
          },
        }}
        bg={'gray'}
        fullScreen
        opened={manageWorkspacesToggle}
        onClose={() => setManageWorkspacesToggle(false)}
      >
        <Flex
          direction={'column'}
          m={'sm'}
          style={{
            background: 'var(--mantine-color-white)',
          }}
        >
          <Grid mt={16} p={'lg'} justify="space-between">
            <Grid.Col span={5}>
              <Flex align={'center'}>
                {(defaultPermissions && checkPermission(defaultPermissions, ['add_workspace'])) && <Button size="xs" onClick={() => setCreateToggle(true)}>
                  Create New
                </Button>}
                <TextInput
                  w={160}
                  size="xs"
                  ml={8}
                  value={search}
                  onChange={(event) => setSearch(event.currentTarget.value)}
                  placeholder="Search workspace"
                  leftSection={
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{
                        color: theme.colors.gray[5],
                      }}
                    />
                  }
                />
              </Flex>
            </Grid.Col>
            <Grid.Col span={2}>
              <Flex align="center" justify={'center'}>
                <Text size="md" fw="bold" ta="center">
                  Manage Workspaces
                </Text>
              </Flex>
            </Grid.Col>
            <Grid.Col span={5}>
              {/* <Flex align={'center'} justify={'end'}>
                <SegmentedControl
                  color="primary"
                  size="xs"
                  data={[
                    { label: 'Active', value: 'active' },
                    { label: 'Archived', value: 'archived' },
                  ]}
                  value={listingType}
                  onChange={setListingType}
                />
              </Flex> */}
            </Grid.Col>
          </Grid>
          <Box
            style={{
              flexGrow: 1,
            }}
          >
            <Table stickyHeader verticalSpacing={'md'}>
              <Table.Thead h={20} bg={'gray.1'} c="gray.7" fz="xs">
                <Table.Tr>
                  <Table.Th>Name</Table.Th>
                  <Table.Th ta="center">Campaigns</Table.Th>
                  <Table.Th ta="center">Links</Table.Th>
                  <Table.Th ta={'center'}>Total Clicks</Table.Th>
                  <Table.Th ta={'center'}>Unique Clicks</Table.Th>
                  <Table.Th ta={'center'}>QR Scans</Table.Th>
                  <Table.Th ta={'center'}>Default Workspace</Table.Th>
                  <Table.Th ta={'center'}>Actions</Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {loading ? (
                  <>
                    <LoaderDataTable colSpan={8} />
                  </>
                ) : (
                  <>
                    {workspaces.length > 0 ? (
                      <>
                        {workspaces
                          .filter((ws) => ws.name.toLowerCase().includes(debounced))
                          .map((workspace: IWorkspace) => (
                            <Table.Tr
                              key={workspace._id}
                            // style={{
                            //   opacity: workspace.archived ? 0.5 : undefined,
                            // }}
                            >
                              <Table.Td>
                                <Flex
                                  align={'center'}
                                  onClick={() => handleWorkspaceChange(workspace)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <Avatar
                                    mr={16}
                                    size={'sm'}
                                    src={workspace.logo}
                                    color="primary"
                                  >
                                    {workspace.name[0]}
                                  </Avatar>
                                  <Flex direction={'column'}>
                                    <Flex align={'center'}>
                                      <Text
                                        fw={600}
                                        fz={13}
                                        style={{
                                          transition: 'font-size 0.3s ease'
                                        }}
                                        onMouseEnter={(e) => {
                                          e.currentTarget.style.fontSize = '13.2px';
                                        }}
                                        onMouseLeave={(e) => {
                                          e.currentTarget.style.fontSize = '13px';
                                        }}
                                      >
                                        {workspace.name}
                                      </Text>
                                    </Flex>
                                    <Text pt={4} c="dimmed" fz={10}>
                                      Created {getRelativeTime(workspace.created_at)}
                                    </Text>
                                  </Flex>
                                </Flex>
                              </Table.Td>
                              <Table.Td ta="center">
                                <Text fw={500} fz={14}>
                                  {workspace.total_campaigns}
                                </Text>
                              </Table.Td>
                              <Table.Td ta="center">
                                <Text fw={500} fz={14}>
                                  {workspace.total_links}
                                </Text>
                              </Table.Td>

                              <Table.Td ta="center">
                                {workspaceStatsLoading ? (
                                  <Center>
                                    <Skeleton h={8} w={100} />
                                  </Center>
                                ) : (
                                  <>
                                    <Text fz={14}>
                                      {numberToCommas(
                                        findStatsByWorkspaceId(workspace._id).clicks
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Table.Td>
                              <Table.Td ta="center">
                                {workspaceStatsLoading ? (
                                  <Center>
                                    <Skeleton h={8} w={100} />
                                  </Center>
                                ) : (
                                  <>
                                    <Text fz={14}>
                                      {numberToCommas(
                                        findStatsByWorkspaceId(workspace._id).unique_clicks
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Table.Td>
                              <Table.Td ta="center">
                                {workspaceStatsLoading ? (
                                  <Center>
                                    <Skeleton h={8} w={100} />
                                  </Center>
                                ) : (
                                  <>
                                    <Text fz={14}>
                                      {numberToCommas(
                                        findStatsByWorkspaceId(workspace._id).qr_scans
                                      )}
                                    </Text>
                                  </>
                                )}
                              </Table.Td>
                              <Table.Td ta="center">
                                <Center>
                                  <Switch
                                    styles={{
                                      track: {
                                        cursor: 'pointer',
                                      },
                                    }}
                                    onChange={(event) => {
                                      if (workspace.is_default) {
                                        notifications.show({
                                          color: 'red',
                                          message: 'Default workspace cannot be turned off.',
                                        });
                                        return;
                                      }
                                      if (event.currentTarget.checked) {
                                        handleDefaultWorkspace(workspace);
                                      }
                                    }}
                                    size="sm"
                                    checked={workspace.is_default}
                                  />
                                </Center>
                              </Table.Td>

                              <Table.Td ta="center">
                                <Flex align={'center'} justify={'center'}>
                                  {isWorkspaceLocked(workspace) ? (
                                    <Tooltip label="Locked">
                                      <ActionIcon
                                        c="gray"
                                        variant="subtle"
                                        onClick={() => {
                                          setUpgradeModalState(true);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faLock} />
                                      </ActionIcon>
                                    </Tooltip>) : (
                                    <>
                                      {(defaultPermissions && checkPermission(defaultPermissions, ['view_workspace'])) && <Tooltip label="View Stats">
                                        <ActionIcon
                                          c="gray"
                                          variant="subtle"
                                          onClick={() => {
                                            setManageWorkspacesToggle(false);
                                            setDefaultWorkspace(workspace);
                                            navigate(`/analytics/${workspace._id}/short-links`);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faLineChart} />
                                        </ActionIcon>
                                      </Tooltip>}
                                      {(defaultPermissions && checkPermission(defaultPermissions, ['edit_workspace'])) && <Tooltip label="Edit">
                                        <ActionIcon
                                          c="gray"
                                          variant="subtle"
                                          onClick={() => {
                                            setManageWorkspacesToggle(false);
                                            setDefaultWorkspace(workspace);
                                            navigate(`/workspace/${workspace._id}/basic`);
                                          }}
                                        >
                                          <FontAwesomeIcon icon={faPencil} />
                                        </ActionIcon>
                                      </Tooltip>}
                                    </>
                                  )}
                                  {(defaultPermissions && checkPermission(defaultPermissions, ['delete_workspace'])) && <Tooltip label="Delete">
                                    <ActionIcon
                                      c="red"
                                      variant="subtle"
                                      onClick={() => {
                                        if (workspace.is_default) {
                                          notifications.show({
                                            color: 'red',
                                            message: 'Default workspace cannot be deleted.',
                                          });
                                          return;
                                        }
                                        confirmDeleteModal(workspace);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faTrash} />
                                    </ActionIcon>
                                  </Tooltip>}
                                </Flex>
                              </Table.Td>
                            </Table.Tr>
                          ))}

                        {workspaces.filter((ws) => ws.name.toLowerCase().includes(debounced))
                          .length === 0 && (
                            <>
                              <Table.Tr>
                                <Table.Td colSpan={8}>
                                  <Center>
                                    <Text size="sm">
                                      {debounced.length > 0
                                        ? 'There are no workspaces for your search term.'
                                        : 'You do not have any workspaces'}
                                    </Text>
                                  </Center>
                                </Table.Td>
                              </Table.Tr>
                            </>
                          )}
                      </>
                    ) : (
                      <>
                        {' '}
                        <Table.Tr>
                          <Table.Td colSpan={6}>
                            <Center>
                              <Text size="sm">You do not have any workspaces.</Text>
                            </Center>
                          </Table.Td>
                        </Table.Tr>
                      </>
                    )}
                  </>
                )}
              </Table.Tbody>
            </Table>
            {/* Add pagination controls */}
            <Group justify="center" mt="md" mb="md">
              <Pagination
                value={page}
                onChange={setPage}
                total={totalPages}
                size="xs"
              />
            </Group>
          </Box>
        </Flex>
      </Modal>
    </>
  );
}

import React, { useState, useRef, useEffect } from 'react';
import { Button } from '@mantine/core';
import { CSVBoxButton } from '@csvbox/react';
import { CSV_LICENSE_KEY, CSV_USER_ID, CSV_LICENSE_KEY_TRIAL } from '@/constants/Constants';
import { usePlanStore } from '@/stores/usePlanStore';

interface CSVButtonProps {
    workspace_id: string | undefined;
    user_id: string | undefined;
    campaign_id: string | undefined;
    tags: string[];
    runOnBackground: boolean | undefined;
    discardInvalidSlug: boolean | undefined;
    allowDuplicateSlug: boolean | undefined;
    mailIframeDisabledLink: boolean | undefined;
    onClose: () => void;
}

const CSVButton: React.FC<CSVButtonProps> = ({ workspace_id, campaign_id, tags, user_id, runOnBackground, discardInvalidSlug, allowDuplicateSlug, mailIframeDisabledLink, onClose}) => {
    const [campaignId, setCampaignId] = useState<string | undefined>('')
    const [bgJob, setBgJob] = useState<boolean | undefined>(false)
    const [dISlug, setDISlug] = useState<boolean | undefined>(false)
    const [aDSlug, setADSlug] = useState<boolean | undefined>(false)
    const [mIFDLinks, setMIFDLinks] = useState<boolean | undefined>(false)
    const [tagsArray, setTagsArray] = useState<string[]>([])
    const csvBoxButtonRef = useRef(null);
    const [plan] = usePlanStore((state) => [state.plan]);
    useEffect(() => {
        setCampaignId(campaign_id)
        setTagsArray(tags)
        setBgJob(runOnBackground)
        setDISlug(discardInvalidSlug)
        setADSlug(allowDuplicateSlug)
        setMIFDLinks(mailIframeDisabledLink)
    }, [campaign_id, tags, runOnBackground, discardInvalidSlug, allowDuplicateSlug, mailIframeDisabledLink])

    return (
        campaignId && <CSVBoxButton
            ref={csvBoxButtonRef}
            licenseKey={plan?.type_of === "trial" ? CSV_LICENSE_KEY_TRIAL : CSV_LICENSE_KEY}
            user={{
                user_id: { CSV_USER_ID }, // Pass the user_id prop directly
                meta: JSON.stringify({
                    workspace_id: workspace_id,
                    campaign_id: campaignId,
                    tags: tagsArray,
                    user_id: user_id,
                    enable_background_job: bgJob,
                    disable_invalid_slug: dISlug,
                    allow_duplicate_slug: aDSlug, 
                    mail_iframe_disable_links: mIFDLinks
                })
            }}
            onImport={(result, data) => {
                if (data?.external_validations_failed_requests === 0 ) {
                    onClose()
                }
            }}
            render={(launch, isLoading) => {
                return <Button size="m" mx={8} disabled={isLoading} onClick={launch}>Add CSV File</Button>;
            }}
        >
            Import
        </CSVBoxButton>
    );
}

export default CSVButton;
import { useEffect, useRef, useState } from 'react';
import { Box, Input, Tooltip } from '@mantine/core';
import { useClickOutside } from '@mantine/hooks';

interface CustomOption {
    label: string;
    value: string;
}

interface CustomSelectProps {
    options: CustomOption[];
    value: string | null;
    onChange: (value: string | null) => void;
    onSearch?: (query: string) => void;
    disabled?: boolean;
    placeholder?: string;
    isLoading?: boolean;
    onScrollEnd?: () => void;
    canClear?: boolean; // Add this prop to control if clearing is allowed
}

const InfiniteScrollSelect = ({
    options,
    value,
    onChange,
    onSearch,
    disabled = false,
    placeholder = 'Select...',
    isLoading = false,
    onScrollEnd,
    canClear = true  // Default to true
}: CustomSelectProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const selectedOption = options.find(opt => opt.value === value);

    // Corrected useClickOutside usage
    useClickOutside(() => setIsOpen(false), null, [containerRef.current]);

    const handleScroll = (e: React.UIEvent<HTMLDivElement>) => {
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + clientHeight) < 50 && onScrollEnd && !isLoading) {
            onScrollEnd();
        }
    };

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        const query = e.target.value;
        setSearchValue(query);
        onSearch?.(query);
    };

    return (
        <div ref={containerRef} style={{ position: 'relative', width: '200px' }}>
            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
                <Input
                    ref={inputRef}
                    value={isOpen ? searchValue : selectedOption?.label || ''}
                    onChange={handleSearch}
                    onClick={() => !disabled && setIsOpen(true)}
                    placeholder={placeholder}
                    disabled={disabled}
                    readOnly={!isOpen}
                    styles={{
                        input: {
                            fontSize: '12px',
                            padding: '6px 12px',
                            paddingRight: canClear && value ? '30px' : '12px', // Add padding for clear button
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis'
                        }
                    }}
                />
                {canClear && value && !disabled && (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            onChange(null);
                            setSearchValue('');
                        }}
                        style={{
                            position: 'absolute',
                            right: '8px',
                            top: '50%',
                            transform: 'translateY(-50%)',
                            cursor: 'pointer',
                            color: '#909296',
                            fontSize: '14px',
                            padding: '4px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2
                        }}
                    >
                        ✕
                    </div>
                )}
            </div>

            {isOpen && (
                <div
                    style={{
                        position: 'absolute',
                        top: '100%',
                        left: 0,
                        right: 0,
                        maxHeight: '200px',
                        overflowY: 'auto',
                        background: 'white',
                        border: '1px solid #ddd',
                        borderRadius: '4px',
                        zIndex: 1000,
                        marginTop: '4px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        width: '100%',
                        // Modern scrollbar styles
                        scrollbarWidth: 'thin', // Firefox
                        scrollbarColor: '#909296 transparent', // Firefox
                        '&::-webkit-scrollbar': {
                            width: '6px',
                            height: '6px'
                        },
                        '&::-webkit-scrollbar-track': {
                            background: 'transparent'
                        },
                        '&::-webkit-scrollbar-thumb': {
                            backgroundColor: '#909296',
                            borderRadius: '3px',
                            '&:hover': {
                                backgroundColor: '#373A40'
                            }
                        }
                    } as any}
                    onScroll={handleScroll}
                >
                    {options.map((option) => (
                        <div
                            key={option.value}
                            onClick={() => {
                                onChange(option.value);
                                setIsOpen(false);
                                setSearchValue('');
                            }}
                            style={{
                                padding: '6px 12px',
                                cursor: 'pointer',
                                backgroundColor: option.value === value ? '#f0f0f0' : 'white',
                                fontSize: '12px',
                                whiteSpace: 'normal', // Allow text to wrap
                                wordBreak: 'break-all', // Break long words
                                lineHeight: '1.4', // Better line height for readability
                                minHeight: '32px', // Minimum height for each option
                                display: 'flex',
                                alignItems: 'center', // Center content vertically
                                width: '100%' // Ensure full width
                            }}
                        >
                            {option.label}
                        </div>
                    ))}
                    {isLoading && (
                        <div style={{ padding: '8px 12px', textAlign: 'center' }}>
                            Loading...
                        </div>
                    )}
                    {!isLoading && options.length === 0 && (
                        <div style={{ padding: '8px 12px', textAlign: 'center' }}>
                            No options found
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default InfiniteScrollSelect;
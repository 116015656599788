import { ExportLinksButton } from '@/components/ExportLinksButton/ExportLinksButton';
import { CampaignsMenu } from '@/components/Menus/CampaignsMenu/CampaignsMenu';
import { TagsMenu } from '@/components/Menus/TagsMenu/TagsMenu';
import { SplitButton } from '@/components/SplitButton/SplitButton';
import CSVButton from '@/components/CSVImport/CSVButton';
import { useLinksStore } from '@/stores/useLinksStore';
import { useUserStore } from '@/stores/useUserStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { checkPermission } from '@/utils/CommonUtility';
import {
  faBoxArchive,
  faCircleExclamation,
  faDownload,
  faEllipsisVertical,
  faLink,
  faMagnifyingGlass,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ActionIcon,
  Box,
  Flex,
  Grid,
  Group,
  Menu,
  Space,
  Text,
  TextInput,
  Tooltip,
  useMantineTheme,
} from '@mantine/core';
import { useMemo } from 'react';
import CustomDateMenu from '@/components/Menus/DateMenu/CustomDateMenu';

interface LinksHeaderProps {
  createModalOpen: boolean;
  setCreateModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function LinksHeader({ createModalOpen, setCreateModalOpen }: LinksHeaderProps) {
  const [archive, setArchive, setDate, search, setSearch, setPage] = useLinksStore((state) => [
    state.archive,
    state.setArchive,
    state.setDate,
    state.search,
    state.setSearch,
    state.setPage,
  ]);
  const theme = useMantineTheme();
  const [defaultWorkspace] = useWorkspaceStore(state => [
    state.defaultWorkspace
  ]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [user] = useUserStore(state => [
    state.user
  ]);

  const handleDateChange = (startDate: string | null, endDate: string | null, granularity: "hour" | "day" | "week" | "month" | "all") => {
    setPage(1);
    if (startDate && endDate) {
      setDate({
        label: 'Custom', // You can customize this based on the selected range
        value: `${startDate}_${endDate}`
      });
    } else {
      setDate({ label: 'All time', value: '' });
    }
  };


  return (
    <>
      <Grid p={'lg'} justify="space-between">
        <Grid.Col span={5}>
          <Flex align={'center'}>
            <Group>
              {(defaultPermissions && checkPermission(defaultPermissions, ['add_link'])) && <SplitButton createModalOpen={createModalOpen} setCreateModalOpen={setCreateModalOpen} />}
              {/* <CSVButton workspace_id={defaultWorkspace?._id} user_id={user?._id}/> */}
            </Group>
            <TextInput
              w={160}
              size="xs"
              ml={8}
              value={search}
              placeholder="Search Links"
              onChange={(event) => {
                setPage(1)
                setSearch(event.currentTarget.value)
              }}
              leftSection={
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  style={{
                    color: theme.colors.gray[5],
                  }}
                />
              }
            />
          </Flex>
        </Grid.Col>
        <Grid.Col span={2}>
          <></>
        </Grid.Col>
        <Grid.Col span={5}>
          <Flex align={'center'} justify={'end'} w={'100%'}>
            <CustomDateMenu
              showButtons={{
                all_time: true,
                last_24_hours: true,
                last_7_days: true,
                last_14_days: true,
                last_30_days: true,
                last_90_days: false,
                last_6_months: true,
                last_1_year: true,
                this_month: true,
                last_month: true,
                "7D": false,
                "14D": false,
                "30D": false,
                "3M": false,
                "6M": false,
                "12M": false,
                "no-range": true,
                custom: true
              }}
              onDateChange={handleDateChange}
              value={[null, null]}
              withGranularity={false}
            />
            <Space w={12} />
            <TagsMenu />
            <Space w={12} />
            <CampaignsMenu />
            <Space w={12} />
            {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && <ExportLinksButton />}
            <Space w={12} />
            {(defaultPermissions && checkPermission(defaultPermissions, ['edit_link'])) && <Menu>
              <Menu.Target>
                <ActionIcon variant="outline" color="gray">
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown ml={-16}>
                <Menu.Item
                  onClick={() => setArchive(!archive)}
                  leftSection={
                    <>
                      <FontAwesomeIcon icon={archive ? faLink : faBoxArchive} />
                    </>
                  }
                >
                  {archive ? 'View Active Links' : 'View Archived Links'}
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>}
          </Flex>
        </Grid.Col>
      </Grid>
    </>
  );
}

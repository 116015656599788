import { TotalResultsMenu } from '@/components/Menus/TotalResultsMenu.tsx/TotalResultsMenu';
import { LinksTable } from '@/pages/Links/LinksTable';
import { Box, Flex, Group, Pagination, useMantineTheme, Text, Tooltip } from '@mantine/core';
import { LinksHeader } from './LinksHeader';
import { useLinksStore } from '@/stores/useLinksStore';
import { useDocumentTitle, useLocalStorage } from '@mantine/hooks';
import { REPLUG_LINKS_TOTAL_RESULTS } from '@/constants/Constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { LinkCreationModal } from '@/components/Modals/LinkCreationModal/LinkCreationModal';
import { UTMPresetApplyOrCreate } from '@/components/Modals/UTMPresetModal/UTMPresetApplyOrCreate';
import { useLinkSetupStore } from '@/stores/useLinkSetupStore';
import { useWorkspaceStore } from '@/stores/useWorkspaceStore';
import { usePermissionsStore } from '@/stores/usePermissionsStore';
import { useEffect, useState } from 'react';
import { checkPermission } from '@/utils/CommonUtility';
import { useSearchParams } from 'react-router-dom';


export function LinksPage() {
  const [defaultWorkspace] = useWorkspaceStore((state) => [state.defaultWorkspace]);
  useDocumentTitle('Links | ' + defaultWorkspace?.agency?.name);

  const theme = useMantineTheme();
  const [link, setLink] = useLinkSetupStore((state) => [state.link, state.setLink]);
  const [page, setPage, lastPage, setTotalResults] = useLinksStore((state) => [
    state.page,
    state.setPage,
    state.lastPage,
    state.setTotalResults,
  ]);
  const [defaultPermissions] = usePermissionsStore((state) => [state.defaultPermissions]);
  const [searchParams] = useSearchParams();
  const [createModalOpen, setCreateModalOpen] = useState(false);

  useEffect(() => {
    const action = searchParams.get('action');
    if (action === 'create') {
      setCreateModalOpen(true);
    }
  }, [searchParams]);

  return (
    <>
      <UTMPresetApplyOrCreate
        onChange={(u: string) => {
          setLink({ ...link, url: u });
        }}
      />
      <LinkCreationModal />

      <Flex align={'center'} pl={'lg'} pt={'xs'}>
        <Text size="xl" fw="bold" mr="sm">
          Links
        </Text>
        <Tooltip
          multiline
          w={450}
          position="bottom"
          fz="xs"
          label=" A link is a shortened, customizable link that you can create against any URL you visit. You can share it among your audience and track its performance by examining the metrics like clicks, unique clicks, and conversion rate etc. "
        >
          <FontAwesomeIcon
            style={{
              color: 'var(--mantine-color-gray-6)',
            }}
            icon={faCircleInfo}
          />
        </Tooltip>
      </Flex>
      <LinksHeader createModalOpen={createModalOpen} setCreateModalOpen={setCreateModalOpen} />

      <Flex
        direction={'column'}
        mx={'md'}
        style={{
          background: 'var(--mantine-color-white)',
        }}
      >
        <Box
          style={{
            flexGrow: 1,
          }}
        >
          <LinksTable />
        </Box>
      </Flex>
      <Box mx={'sm'} mb={'xl'} pb={'lg'} mt={'sm'}>
        <Group justify="space-between">
          <Pagination value={page} onChange={setPage} size="xs" total={lastPage} />
          <TotalResultsMenu value={20} onChange={setTotalResults} />
        </Group>
      </Box>
    </>
  );
}
